/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: '100%';
  position: relative;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease-in-out 1500ms;
}

.slide {
  display: inline-block;

  height: 'auto';
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #385242;
}
